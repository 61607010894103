@import 'normalize.css';

@import '~antd/dist/antd.css';

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

a,
.ant-btn-link {
    color: #2c80ff;
}
.ant-btn {
    border-radius: 12px;
}
.ant-btn-primary {
    border-color: rgb(44, 128, 255);
    background: rgb(44, 128, 255);
    background: linear-gradient(145deg, rgba(44, 128, 255, 1) 0%, rgba(41, 101, 249, 1) 100%);
    background-size: 1px 100%;
    &:hover {
        border-color: rgb(44, 128, 255);
        background: linear-gradient(145deg, rgba(44, 128, 255, 1) 0%, rgba(41, 101, 249, 1) 100%);
        background-size: 1px 200%;
    }
}
.ant-btn-link {
    height: auto;
}
.ant-menu-item {
    border-radius: 15px;
    &_selected {
        background: #f4f8fb;
        &:after {
            opacity: 0 !important;
        }
    }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #f4f8fb;
    font-weight: 500;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-color: transparent;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
    opacity: 0;
}
.ant-select-selector,
.ant-select,
.ant-picker,
*:not(.ant-input-affix-wrapper) > .ant-input,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper,
.ant-input-number {
    border-radius: 10px !important;
    border-color: #e9e9e9;
}
.ant-input-number {
    overflow: hidden;
}
.rc-virtual-list-holder,
.ant-select-dropdown,
.ant-picker-panel-container {
    border-radius: 10px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 8%), 0 6px 16px 0 rgb(0 0 0 / 4%),
        0 9px 28px 8px rgb(0 0 0 / 2%);
}
.ant-table-thead > tr > th {
    color: #9fa8b2;
    font-weight: 400;
}
.tag-green,
.tag-volcano,
.tag-orange {
    border-color: transparent !important;
    outline: none;
    border-radius: 10px;
    border: 0;
    padding: 3px 9px;
}
.tag-green {
    background: #6af4bd;
    background: linear-gradient(135deg, #74f2ce 0%, #7cffcb 46%, #6af4bd 100%);
    color: #ffffff;
}
.tag-volcano {
    background: rgb(249, 168, 95);
    background: linear-gradient(
        135deg,
        rgba(249, 168, 95, 1) 0%,
        rgba(255, 128, 120, 1) 46%,
        rgba(249, 90, 151, 1) 100%
    );
    color: #fff;
}
.tag-orange {
    background: rgb(249, 168, 95);
    background: linear-gradient(135deg, #ffac81 0%, #ff928b 46%, #ff928b 100%);

    color: #fff;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
    border-radius: 10px 10px 0 0;
}
.ant-pagination.mini .ant-pagination-item {
    border-radius: 10px;
}

h1.ant-typography,
.ant-typography h1,
h2.ant-typography,
.ant-typography h2,
h3.ant-typography,
.ant-typography h3,
h4.ant-typography,
.ant-typography h4,
h5.ant-typography,
.ant-typography h5 {
    font-family: 'Rubik', sans-serif;
    color: #324071;
}

.ant-select-item-option-content {
    & svg {
        display: inline-block;
        vertical-align: middle;
    }
}
.rdw-editor-toolbar {
    border-color: #e5e5e5 !important;
    margin-bottom: 0 !important;
}
.rdw-editor-main {
    border: 1px solid #e5e5e5;
    border-top: 0;
    margin: 0;
    padding: 5px 10px;
}
.ant-btn-link {
    padding: 0;
}
.row-dragging {
    background: #fafafa;
    border: 1px solid rgb(0 0 0 / 10%);
}
.row-dragging td {
    padding: 16px;
}
.row-dragging .drag-visible {
    visibility: visible;
}
.svg-icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -4px;
}

.rdw-editor-toolbar {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border-color: #e9e9e9 !important;
}
.rdw-editor-main {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-color: #e9e9e9 !important;
}
.rdw-option-wrapper {
    padding: 14px 8px !important;
    border-radius: 10px !important;
    background: transparent;
    border: 1px solid #e9e9e9 !important;
    &:hover {
        outline: none !important;
        box-shadow: none !important;
        border-color: #2c80ff !important;
    }
}
.rdw-dropdown-wrapper {
    border: 1px solid #e9e9e9 !important;
    border-radius: 10px !important;
    &:hover {
        outline: none !important;
        box-shadow: none !important;
        border-color: #2c80ff !important;
    }
}
.rdw-dropdown-selectedtext {
    padding: 14px 8px !important;
    background: transparent;
    padding-right: 20px !important;
}

.ant-typography {
    color: #212529;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
    border-radius: 10px !important;
    border-color: #e9e9e9;
    background: #fff !important;
}

body {
    margin: 0;
    /* font-family: 'IBMPlexSans', 'ChevinPro', 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; */
    font-family: 'Rubik', sans-serif;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    color: #212529;
    background-color: #f9f9f9;
    text-align: left;
}
.container {
    display: block;
    max-width: 100%;
    width: 1200px;
    padding: 0 15px;
    box-sizing: border-box;
    margin: 0 auto;
}
.app {
    min-height: 100vh;
    @media only screen and (max-width: 768px) {
        min-height: auto;
    }
    &_grid {
        display: grid;
        grid-template-columns: 200px 1.8fr;
        grid-template-rows: 70px 1fr;
        gap: 20px 30px;
        grid-template-areas:
            'header header'
            'bar main';
        padding: 10px;
        @media only screen and (max-width: 768px) {
            grid-template-rows: 70px auto;
            grid-template-areas:
                'header header'
                'main main';
        }
    }
    & p {
        margin-top: 0;
        margin-bottom: 0.5em;
    }
}

.ant-table-content {
    @media only screen and (max-width: 1024px) {
        overflow-x: auto;
    }
}
.ant-table table {
    @media only screen and (max-width: 1024px) {
        min-width: max-content;
    }
}
.react-colorful__pointer {
    width: 15px !important;
    height: 15px !important;
}
.react-colorful {
    width: 120px !important;
    height: 120px !important;
}

.header {
    grid-area: header;
    border-radius: 10px;
    padding: 10px;
    height: 100%;
    background: #fff;
    &__container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 5px;
        height: 100%;
        box-sizing: border-box;
    }
    &__logo {
        font-family: 'Rubik', sans-serif;
        font-size: 26px;
        font-weight: 500;
        color: #1890ff;
        &-logo {
            display: inline-block;
            vertical-align: middle;
            width: 90px;
        }
        &-version {
            background: rgb(249, 168, 95);
            background: linear-gradient(
                135deg,
                rgba(249, 168, 95, 1) 0%,
                rgba(255, 128, 120, 1) 46%,
                rgba(249, 90, 151, 1) 100%
            );
            color: #fff;
            display: inline-block;
            vertical-align: top;
            padding: 4px 8px;
            font-size: 11px;
            border-radius: 8px;
            font-weight: normal;
            margin-left: 5px;
        }
    }
    &-account {
        margin-left: auto;
        cursor: pointer;
        padding: 10px 0;
        & .ant-avatar {
            background: rgb(55, 123, 234);
            background: linear-gradient(
                135deg,
                rgba(55, 123, 234, 1) 0%,
                rgba(43, 109, 255, 1) 100%
            );
            border-radius: 10px;
        }
        &__username {
            margin-left: 10px;
            @media only screen and (max-width: 479px) {
                display: none;
            }
        }
    }
    &__open-menu {
        margin-left: 10px;
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
}
.bar {
    grid-area: bar;
    /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    @media only screen and (min-width: 769px) {
        display: block !important;
    }
    @media only screen and (max-width: 768px) {
        position: absolute;
        top: 100px;
        left: 10px;
        right: 10px;
        z-index: 2;
        box-shadow: 0 3px 6px -4px rgb(0 0 0 / 8%), 0 6px 16px 0 rgb(0 0 0 / 4%),
            0 9px 28px 8px rgb(0 0 0 / 2%);
    }
    &_hidden {
        display: none;
    }
    &-inner {
        width: 100%;
        height: auto;
        position: sticky;
        top: 10px;
        left: 0;
    }
    & .ant-menu {
        background: transparent;
    }
}
.main {
    grid-area: main;
    overflow: hidden;
}
.page {
    &__title {
        margin-top: 20px;
        @media only screen and (max-width: 479px) {
            font-size: 25px !important;
        }
    }
}
.activity {
    &__title {
        &-id {
            font-weight: 300;
            font-size: 16px;
        }
    }
    &-info {
        &__list {
            padding: 0;
            margin: 0;
            list-style: none;
            font-size: 14px;
            & li {
                padding: 0;
                margin: 0;
                & b {
                    font-weight: 500;
                }
            }
            & > * + * {
                margin-top: 10px !important;
            }
        }
    }
    &-add {
        &__inner {
            background: #fff;
            padding: 15px;
            border-radius: 15px;
        }
    }
    &-questions {
        margin-top: 20px;
        &__inner {
            background: #fff;
            padding: 15px;
            border-radius: 15px;
        }
        &-item {
            &__controls {
                &-edit {
                    margin-right: 15px;
                }
            }
        }
        &__add {
            margin-top: 20px;
        }
    }
    &-question-add,
    &-question-edit {
        &__inner {
            background: #fff;
            padding: 15px;
            border-radius: 15px;
        }
        &-options {
            &__block {
                border-radius: 10px;
                background: #f9f9f9;
                padding: 10px;
                &-header {
                    display: grid;
                    grid-auto-columns: 1fr;
                    grid-template-columns: 1fr 30px;
                    grid-template-rows: auto;
                    gap: 0 10px;
                    grid-template-areas: '. .';
                }
                &-body {
                    display: grid;
                    grid-auto-columns: 1fr;
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    gap: 0 10px;
                    grid-template-areas: '.';
                    margin-top: 10px;
                }
                &-grid {
                    display: grid;
                    grid-auto-columns: 1fr;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto;
                    gap: 0 15px;
                    grid-template-areas: '. .';
                }
                & + & {
                    margin-top: 10px;
                }
                & .ant-form-item {
                    margin-bottom: 0;
                }
            }
            &__grid {
                display: grid;
                grid-auto-columns: 1fr;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
                gap: 15px;
                grid-template-areas: '. .';
                @media only screen and (max-width: 479px) {
                    grid-template-areas: '.';
                    grid-template-columns: 1fr;
                }
            }
            &__add {
                margin-top: 10px;
            }
        }
        &__min-max {
            display: grid;
            grid-auto-columns: 1fr;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            gap: 15px;
            grid-template-areas: '. .';
            @media only screen and (max-width: 479px) {
                grid-template-areas: '.';
                grid-template-columns: 1fr;
                grid-template-rows: auto;
            }
            & * {
                margin-bottom: 0;
            }
        }
    }
    &-config {
        margin-top: 20px;
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
        }
        &__edit {
            margin-bottom: 0.5em;
        }
        &__inner {
            background: #fff;
            padding: 15px;
            border-radius: 15px;
        }
    }
    &-info {
        margin-top: 20px;
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-wrap: wrap;
        }
        &__edit {
            margin-bottom: 0.5em;
        }
        &__inner {
            background: #fff;
            padding: 15px;
            border-radius: 15px;
        }
        &-edit {
            &__inner {
                background: #fff;
                padding: 15px;
                border-radius: 15px;
            }
        }
    }
    &-scales {
        &-groups {
            margin-top: 20px;
            &__inner {
                background: #fff;
                padding: 15px;
                border-radius: 15px;
            }
            &__form {
                display: grid;
                grid-gap: 0 15px;
                margin-top: 20px;
                grid-template-columns: 1fr 100px;
                & > * {
                    margin-bottom: 0;
                }
            }
        }
    }
    &-question {
        &-scales {
            margin-top: 15px;
            &__title {
                cursor: pointer;
                outline: none;
            }
            &__items {
                display: grid;
                grid-auto-columns: 1fr;
                grid-template-columns: 1fr 1fr 1fr;
                grid-template-rows: auto;
                gap: 15px;
                grid-template-areas: '. . .';
                @media only screen and (max-width: 479px) {
                    grid-template-areas: '. .';
                    grid-template-columns: 1fr 1fr;
                }
            }
            &__item {
            }
        }
    }
    &-clone {
        margin-top: 20px;
        &__inner {
            background: #fff;
            padding: 15px;
            border-radius: 15px;
        }
        &__form {
            display: grid;
            grid-gap: 0 15px;
            margin-top: 20px;
            grid-template-columns: 1fr 100px;
            & > * {
                margin-bottom: 0;
            }
        }
    }
}
.activities {
    &-list {
        background: #fff;
        padding: 15px;
        border-radius: 15px;
        &__search {
            margin-bottom: 20px;
        }
        &__controls {
            display: flex;
            justify-items: center;
            align-items: center;
            & > * + * {
                margin-left: 20px;
            }
        }
        & tr {
            &.disabled {
                opacity: 0.3;
                filter: blur(1px);
            }
        }
    }
    &__add {
        margin-top: 20px;
    }
    &__loading {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: sticky;
        & .ant-spin-text {
            margin-top: 8px;
        }
    }
}
.definition-list {
    margin: 0;
    font-size: 14px;
    &__item {
        display: flex;
        align-items: baseline;
        @media only screen and (max-width: 768px) {
            display: block;
        }
        & + & {
            margin-top: 15px;
        }
    }
    &__term {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 1 auto;
        font-weight: normal;
        @media only screen and (max-width: 768px) {
            font-weight: 500;
        }
    }
    &__dots {
        flex: 1 0 40px;
        margin: 0 2px;
        border-bottom: 1px dotted rgb(0 0 0 / 20%);
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    &__term-container {
        flex: 0 1 300px;
        display: flex;
        align-items: baseline;
        overflow: hidden;
    }
    &__definition {
        flex: 1 1 50%;
        margin: 0;
        overflow: hidden;
        @media only screen and (max-width: 768px) {
            margin-top: 10px;
        }
        & blockquote {
            margin: 0;
        }
        & *:last-child {
            margin-bottom: 0;
        }
        &-value blockquote {
            & * {
                color: #000 !important;
            }
        }
    }
    &__definition_with-copy {
        display: flex;
        align-items: center;
    }
}
.users {
    &__inner {
        background: #fff;
        padding: 15px;
        border-radius: 15px;
    }
    &-item {
        &__controls {
            &-edit {
                margin-right: 15px;
            }
        }
    }
}
.activity-page-edit {
    &__inner {
        background: #fff;
        padding: 15px;
        border-radius: 15px;
    }
}
.scales {
    &__buttons {
        display: flex;
        justify-items: flex-start;
        align-items: center;
        margin-top: 20px;
        & > * + * {
            margin-left: 15px;
        }
    }
    &-list {
        background: #fff;
        padding: 15px;
        border-radius: 15px;
    }
    &-groups,
    & {
        &-add,
        &-edit {
            &__inner {
                background: #fff;
                padding: 15px;
                border-radius: 15px;
            }
        }
    }
    &-add {
        &-range-interpretation {
            &__items {
                display: grid;
                grid-auto-columns: 1fr;
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                gap: 15px;
                grid-template-areas: '.';
            }
            &__item {
                border-radius: 10px;
                background: #f9f9f9;
                padding: 10px;
                &-header {
                    display: grid;
                    grid-auto-columns: 1fr;
                    grid-template-columns: 1fr 1fr 30px;
                    grid-template-rows: auto;
                    gap: 0 10px;
                    grid-template-areas: '. .';
                    margin-top: 20px;
                }
                &-body {
                    display: grid;
                    grid-auto-columns: 1fr;
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    gap: 0 10px;
                    grid-template-areas: '.';
                    margin-top: 10px;
                }
                & .ant-form-item {
                    flex-direction: column !important;
                    margin-bottom: 0;
                }
                &-delete {
                    margin-top: 30px;
                }
            }
            &__add {
                margin-top: 20px;
            }
        }
    }
}
.results {
    &-inner {
        background: #fff;
        padding: 15px;
        border-radius: 15px;
        margin-top: 20px;
    }
}
.result {
    &-scales {
        background: #fff;
        padding: 15px;
        border-radius: 15px;
        margin-top: 20px;
        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            &-item {
                display: inline-block;
                vertical-align: middle;
                margin-top: 5px;
                font-size: 14px;
                line-height: 1.4;
                margin-right: 20px;
            }
        }
    }
    &-answers {
        margin-top: 20px;
        background: #fff;
        padding: 15px;
        border-radius: 15px;
    }
    &-answer {
        & + * {
            margin-top: 20px;
        }
        &__header {
        }
        &__body {
            margin-top: 10px;
        }
        &__title {
            margin-top: 5px;
        }
        &__id {
            font-size: 11px;
            color: rgba(0, 0, 0, 50%);
        }
        &__list {
            &-item {
                background: #f9f9f9;
                padding: 5px 10px;
                border-radius: 10px;
                &_selected {
                    background: #f3f3f3;
                    font-weight: 500;
                }
                & + * {
                    margin-top: 10px;
                }
            }
        }
    }
}
.discharge {
    &-inner {
        background: #fff;
        padding: 15px;
        border-radius: 15px;
        margin-top: 20px;
    }
    &-columns {
        overflow: auto;
        max-height: 300px;
        margin-top: 10px;
    }
    &-column {
        margin-top: 10px;
    }
}
.page-edit {
    &-dependencies {
        &-values {
            &__block {
                padding: 10px;
                border: 1px solid #e5e5e5;
                border-radius: 5px;
                background: #fafafa;
                &-grid {
                    display: grid;
                    grid-auto-columns: 1fr;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto;
                    gap: 0 15px;
                    grid-template-areas: '. .';
                }
                & + & {
                    margin-top: 10px;
                }
                & .ant-form-item {
                    margin-bottom: 0;
                }
            }
            &__grid {
                display: grid;
                grid-auto-columns: 1fr;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
                gap: 0 15px;
                grid-template-areas: '. .';
            }
            &__add {
                margin-top: 10px;
            }
        }
        &-items {
        }
        &-item {
            border: 1px solid #e5e5e5;
            border-radius: 5px;
            padding: 10px;
            background: #fff;
            & + & {
                margin-top: 20px;
            }
            &__header {
                display: grid;
                grid-auto-columns: 1fr;
                grid-template-columns: 1fr 80px 20px;
                gap: 15px 15px;
                grid-template-areas: '. . .';
                width: 100%;
                height: 100%;
            }
            &__delete {
                opacity: 0.4;
                transition: 0.3s;
                margin-top: 5px;
                &:hover {
                    opacity: 0.8;
                }
                & svg {
                    width: 20px;
                    height: 20px;
                }
            }
            &__body {
                margin-top: 15px;
            }
        }
        &__and {
            margin: 0 auto !important;
            margin-top: 10px !important;
            margin-bottom: 10px !important;
            display: table;
            font-weight: 500;
        }
    }
}
.tags {
    &__inner {
        background: #fff;
        padding: 15px;
        border-radius: 15px;
    }
    &__env {
        width: 100%;
        margin-bottom: 20px;
    }
}
